<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body v-if="editMode">
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="telegramMessageSendForm"
          #default="{invalid}"
        >
          <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
          <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
          <div
            v-if="fieldValues[3] && fieldValues[3].value == 'user can enter'"
          >
            <b-form-group
              label="To: "
              label-for="sms-to"
              label-cols-md="1"
            >
              <validation-provider
                #default="{ errors }"
                name="sms"
                vid="sms-to"
                rules="required"
              >
                <b-form-input
                  id="sms-to"
                  v-model="inputValues[0].value"
                  placeholder="Chat ID (Separate with comma if multiple)"
                  :state="(errors.length > 0) ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <div
            v-else
          >
            <p>
              To: {{ inputValues[0].value }}
            </p>
          </div>
          <b-form-group
            label=""
            label-for="sms-body"
          >
            <validation-provider
              #default="{ errors }"
              name="Message"
              vid="sms-body"
              rules="required"
            >
              <b-form-textarea
                id="sms-body"
                v-model="inputValues[1].value"
                :placeholder="fieldValues[4] ? fieldValues[4].value : ''"
                :state="(errors.length > 0) ? false : null"
              />
            </validation-provider>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-2"
            :disabled="invalid || !inputValues[0].value"
          >
            Send Message
          </b-button>
          <b-button
            v-if="submitDate"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-secondary"
            type="button"
            class="ml-1 mt-2"
            @click="editMode = false"
          >
            Cancel
          </b-button>
        </validation-observer>
      </b-form>
    </b-card-body>
    <b-card-body v-else>
      <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
      <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
      <p>
        To: {{ inputValues[0].value }}
      </p>
      <p>Message: {{ inputValues[1] ? inputValues[1].value : '' }}</p>
      <b-row>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-50"
            @click="editMode = true"
          >
            Edit & Resend Message
          </b-button>
        </b-col>
        <b-col md="6">
          <div class="success-background p-1">
            <span class="success-text">
              Success! A message was sent on {{ dateFormatWithTime(submitDate) }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton, BFormTextarea, BRow, BCol, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    submitDate: {
      type: String,
      default: null,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
    serviceRequestString: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      editMode: false,
    }
  },
  created() {
    if (this.submitDate) {
      this.editMode = false
    } else {
      this.editMode = true
    }
    if (!this.inputValues.length) {
      const recipientObj = {}
      recipientObj.key = 'number'
      if (this.fieldValues[3] && this.fieldValues[3].value === 'user can enter') {
        recipientObj.value = ''
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'pre-defined recipient') {
        let list = ''
        this.customFields[0].value.forEach((arrayItem, index) => {
          if ((index + 1) < this.customFields[0].value.length) {
            list += `${arrayItem}, `
          } else {
            list += arrayItem
          }
        })
        recipientObj.value = list
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'data-source') {
        if (this.customFields[0].value === 'Service Form') {
          if (this.customFields[1].value === 'Agent') {
            recipientObj.value = this.serviceForm.agentContact
          } else {
            recipientObj.value = this.serviceForm.customerContact
          }
        } else {
          this.$http.get(`directory/users/${this.customFields[1].value}/show`)
            .then(() => {
              recipientObj.value = ''
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'another block') {
        const formData = new FormData()

        formData.append('serviceRequest', this.serviceRequestId)
        formData.append('block', this.taskBlock)
        formData.append('field', this.customFields[1].value)

        this.$http.post('operation/service-requests/get-recipient/from-block', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            const block = response.data.data.taskBlocks.find(taskBlock => taskBlock._id === this.taskBlock)
            const field = block.fieldValues.find(element => element.key === this.customFields[1].value)
            recipientObj.value = field.value ?? ''
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
      this.inputValues.push(recipientObj)

      const bodyObj = {}
      bodyObj.key = 'body'
      bodyObj.value = this.fieldValues[5].value
      this.inputValues.push(bodyObj)
    }
    this.$emit('updateCount')
  },
  methods: {
    submitForm() {
      this.$refs.telegramMessageSendForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('serviceRequest', this.serviceRequestId)
          formData.append('block', this.taskBlock)
          formData.append('inputValues', JSON.stringify(this.inputValues))

          this.$http.post('operation/service-requests/telegram-message-task/send', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                const telegramURL = 'https://api.telegram.org/bot'
                const botToken = process.env.VUE_APP_TELEGRAM_BOT_TOKEN
                const chatID = this.inputValues[0].value.split(',')
                // const chatID = '-1001214367772'
                const pageURL = `[For Service Request ${this.serviceRequestString}](${window.location.href})`
                // console.log(window.location.href)
                const replacedText = this.inputValues[1].value.replace(/(\[[^\][]*]\(http[^()]*\))|[_*[\]()~`>+=|{}.!-]/gi,
                  // eslint-disable-next-line no-useless-escape
                  (x, y) => (y || `\\${x}`)).replace(/\#/g, '')
                const message = encodeURI(`From ${this.user.name}:\n${replacedText}\n${pageURL}`)
                chatID.forEach(id => {
                  const finalURL = `${telegramURL}${botToken}/sendMessage?chat_id=${id.trim()}&text=${message}&parse_mode=MarkdownV2`
                  this.$http.get(finalURL)
                    .then(result => {
                      if (result.data.ok === true) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Message Sent',
                            icon: 'AlertTriangleIcon',
                            variant: 'success',
                          },
                        })
                      }
                    })
                    .catch(error => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: error.data.description,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    })
                })
                // @ts-ignorethis.$emit('update:statusFilter', response.data.data)
                // this.$swal({
                //   title: 'Message Sent',
                //   html: response.data.message || '',
                //   // eslint-disable-next-line global-require
                //   imageUrl: require('@/assets/images/icons/save.png'),
                //   imageWidth: 80,
                //   imageHeight: 80,
                //   imageAlt: 'Custom Icon',
                //   showCancelButton: false,
                //   cancelButtonText: '',
                //   allowOutsideClick: false,
                //   confirmButtonText: 'Ok',
                //   customClass: {
                //     confirmButton: 'btn btn-primary mr-1',
                //     cancelButton: 'btn btn-primary',
                //   },
                //   buttonsStyling: false,
                // })
                // .then(result => {
                //   if (result.value) {
                //     this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
                //   } else {
                //
                //     this.$router.push({ name: 'appointment-bookings-show', params: { id: response.data.data._id } })
                //   }
                // })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .pale-background {
    background: rgba(186, 191, 199, 0.12);
  }
</style>
