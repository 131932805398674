<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body v-if="editMode">
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="emailSendForm"
          #default="{invalid}"
        >
          <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
          <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
          <div
            v-if="fieldValues[3] && fieldValues[3].value == 'user can enter'"
          >
            <b-form-group
              label="To: "
              label-for="email-to"
              label-cols-md="1"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email-to"
                rules="required|email"
              >
                <b-form-input
                  id="email-to"
                  v-model="inputValues[0].value"
                  placeholder="Email"
                  :state="(errors.length > 0) ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <div
            v-else
          >
            <p>
              To: {{ inputValues[0].value }}
            </p>
          </div>
          <hr class="dividerHR">
          <!-- <p>Subject: {{ fieldValues[4] ? fieldValues[4].value : '' }}</p> -->
          <div
            v-if="inputValues[3]"
          >
            <b-form-group
              label="Subject: "
              label-for="email-subject"
              label-cols-md="1"
            >
              <validation-provider
                #default="{ errors }"
                name="Subject"
                vid="email-subject"
                rules="required"
              >
                <b-form-input
                  id="email-to"
                  v-model="inputValues[3].value"
                  placeholder="Subject"
                  :state="(errors.length > 0) ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <hr class="dividerHR">
          <b-form-group
            label=""
            label-for="email-body"
          >
            <validation-provider
              name="email-body"
              vid="email-body"
              rules="required"
            >
              <quill-editor
                v-model="inputValues[1].value"
                :options="editorOption"
              >
                <div
                  :id="'toolbar-'+taskBlock"
                  slot="toolbar"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold">
                    Bold
                  </button>
                  <button class="ql-italic">
                    Italic
                  </button>
                  <button class="ql-underline">
                    Underline
                  </button>
                  <select class="ql-size">
                    <option value="small" />
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected />
                    <option value="large" />
                    <option value="huge" />
                  </select>

                  <select class="ql-align">
                    <option selected="selected" />
                    <option value="center" />
                    <option value="right" />
                    <option value="justify" />
                  </select>
                </div>
              </quill-editor>
            </validation-provider>
          </b-form-group>
          <b-row>
            <b-col cols="md-4">
              <p>Attachment</p>
            </b-col>
            <b-col cols="md-8">
              <b-form-file
                v-model="addedFile"
                multiple
                :accept="fieldValues[6] ? fieldValues[6].value.toString() : ''"
                @input="setAttachment"
              />
            </b-col>

            <!-- <b-col cols="md-12">
              <div
                v-for="(file, key) in files"
                :key="key"
                class="mt-2 pale-background"
              >
                <span>{{ file ? file.type : '' }}</span>
                <strong>{{ file ? file.name : '' }}</strong>
                <span>{{ file ? `${(file.size / (1024 * 1024)).toFixed(2)}mb` : '' }}</span>
                <feather-icon
                  icon="XIcon"
                  class=""
                  size="16"
                  @click="removeFile(key)"
                />
              </div>
            </b-col> -->
            <b-col
              v-for="(file, key) in files"
              :key="key"
              class="content-header-left mb-2 mt-2"
              cols="12"
              md="6"
            >
              <div
                class="attachment-item"
              >
                <b-img
                  v-if="file.type.includes('image')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_jpg.png')"
                  alt="Left image"
                />
                <b-img
                  v-if="file.type.includes('application')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <!-- <strong>{{ file ? file.name : '' }}</strong> -->
                <strong>
                  <b-link
                    :href="file ? (file.url ? file.url : file.data) : ''"
                    target="_blank"
                  >
                    {{ file ? file.name : '' }}
                  </b-link>
                </strong>
                <span>{{ file ? `${file.size}mb` : '' }}</span>
                <feather-icon
                  icon="XIcon"
                  class="remove__doc"
                  size="16"
                  @click="removeFile(key)"
                />
              </div>
              <!-- <div class="doc__desc">
                <span><button>Add a description</button></span>
                <span class="edit__desc">
                  <b-img
                    :src="require('@/assets/images/admin/edit.svg')"
                  />
                </span>
              </div> -->
            </b-col>
          </b-row>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-2"
            :disabled="invalid || !inputValues[0].value"
          >
            Send
          </b-button>
          <b-button
            v-if="submitDate"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-secondary"
            type="button"
            class="ml-1 mt-2"
            @click="editMode = false"
          >
            Cancel
          </b-button>
        </validation-observer>
      </b-form>
    </b-card-body>
    <b-card-body v-else>
      <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
      <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
      <p>
        To: {{ inputValues[0].value }}
      </p>
      <p>Subject: {{ inputValues[3] ? inputValues[3].value : '' }}</p>
      <p>Body:</p>
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="inputValues[1].value" />
      <!--eslint-enable-->
      <!-- <div
        v-for="(file, key) in inputValues[2].value"
        :key="key"
        class="mt-2 pale-background"
      >
        <span>{{ file ? file.type : '' }}</span>
        <strong>{{ file ? file.name : '' }}</strong>
        <span>{{ file ? `${file.size}mb` : '' }}</span>
      </div> -->
      <b-row>
        <!-- Content Left -->
        <b-col
          v-for="(file, key) in inputValues[2].value"
          :key="key"
          class="content-header-left mb-2 mt-2"
          cols="12"
          md="6"
        >
          <div
            class="attachment-item"
          >
            <b-img
              v-if="file.type.includes('image')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_jpg.png')"
              alt="Left image"
            />
            <b-img
              v-if="file.type.includes('application')"
              left
              height="40"
              :src="require('@/assets/images/admin/doc_file.png')"
              alt="Left image"
            />
            <strong>
              <b-link
                :href="file ? file.data : ''"
                target="_blank"
              >
                {{ file ? file.name : '' }}
              </b-link>
            </strong>
            <span>{{ file ? `${file.size}mb` : '' }}</span>
            <!-- <span class="remove__doc">
              <b-img
                :src="require('@/assets/images/admin/x.svg')"
              />
            </span> -->
          </div>
          <!-- <div class="doc__desc">
            <span><button>Add a description</button></span>
            <span class="edit__desc">
              <b-img
                :src="require('@/assets/images/admin/edit.svg')"
              />
            </span>
          </div> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-50 float-left"
            @click="editMode = true"
          >
            Edit & Resend Email
          </b-button>
        </b-col>
        <b-col md="6">
          <div class="success-background p-1">
            <span class="success-text">
              Success! An email was sent on {{ dateFormatWithTime(submitDate) }}
            </span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BFormFile, BButton, BRow, BCol, BForm, BFormInput, BFormGroup, BImg, BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BImg,
    BLink,

    ValidationProvider,
    ValidationObserver,

    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    submitDate: {
      type: String,
      default: null,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: `#toolbar-${this.taskBlock}`,
        },
        placeholder: 'Compose message',
      },
      editMode: false,
      files: [],
      addedFile: [],
      totalFileSize: 0,
    }
  },
  created() {
    if (this.submitDate) {
      this.editMode = false
    } else {
      this.editMode = true
    }
    this.$emit('updateCount')
    if (!this.inputValues.length) {
      const emailObj = {}
      emailObj.key = 'email'
      if (this.fieldValues[3] && this.fieldValues[3].value === 'user can enter') {
        emailObj.value = ''
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'pre-defined recipient') {
        let list = ''
        this.customFields[0].value.forEach((arrayItem, index) => {
          if ((index + 1) < this.customFields[0].value.length) {
            list += `${arrayItem}, `
          } else {
            list += arrayItem
          }
        })
        emailObj.value = list
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'data-source') {
        if (this.customFields[0].value === 'Service Form') {
          if (this.customFields[1].value === 'Agent') {
            emailObj.value = ''
          } else {
            emailObj.value = this.serviceForm.customerEmail
          }
        } else {
          this.$http.get(`directory/users/${this.customFields[1].value}/show`)
            .then(response => {
              emailObj.value = response.data.email ?? ''
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      } else if (this.fieldValues[3] && this.fieldValues[3].value === 'another block') {
        const formData = new FormData()

        formData.append('serviceRequest', this.serviceRequestId)
        formData.append('block', this.taskBlock)
        formData.append('field', this.customFields[1].value)

        this.$http.post('operation/service-requests/get-recipient/from-block', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            const block = response.data.data.taskBlocks.find(taskBlock => taskBlock._id === this.taskBlock)
            const field = block.fieldValues.find(element => element.key === this.customFields[1].value)
            emailObj.value = field.value ?? ''
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
      this.inputValues.push(emailObj)

      const bodyObj = {}
      bodyObj.key = 'body'
      bodyObj.value = this.fieldValues[5].value
      this.inputValues.push(bodyObj)

      const attachmentObj = {}
      attachmentObj.key = 'attachment'
      attachmentObj.value = []
      this.inputValues.push(attachmentObj)

      const subjectObj = {}
      subjectObj.key = 'subject'
      subjectObj.value = this.fieldValues[4].value
      this.inputValues.push(subjectObj)
    } else {
      this.files = this.inputValues[2].value
      this.totalFileSize = this.inputValues[2].value.reduce((a, b) => +a + +b.size, 0)
    }
    if (!this.inputValues[3]) {
      const subjectObj = {}
      subjectObj.key = 'subject'
      subjectObj.value = this.fieldValues[4].value
      this.inputValues.push(subjectObj)
    }
  },
  methods: {
    removeFile(key) {
      const element = this.files[key]
      this.totalFileSize -= element.size
      this.files.splice(key, 1)
    },
    setAttachment() {
      if (this.addedFile.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   this.fileSrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'text/csv', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        this.addedFile.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if ((this.totalFileSize + +(element.size / (1024 * 1024)).toFixed(2)) > 20) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 20mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.totalFileSize += +(element.size / (1024 * 1024)).toFixed(2)
              // this.files.push(element)
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.url = URL.createObjectURL(element)
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                fileObj.description = ''
                this.files.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    submitForm() {
      this.$refs.emailSendForm.validate().then(async success => {
        if (success) {
          const formData = new FormData()

          formData.append('serviceRequest', this.serviceRequestId)
          formData.append('block', this.taskBlock)
          this.inputValues[2].value = this.files.map(({ url, ...keepAttrs }) => keepAttrs)
          // eslint-disable-next-line arrow-body-style
          // const fileProcessPromises = this.files.map(element => {
          //   // Return a promise per file
          //   return new Promise((resolve, reject) => {
          //     const reader = new FileReader()
          //     // reader.readAsDataURL(element)
          //     reader.onload = event => {
          //       const fileObj = {}
          //       fileObj.name = element.name
          //       fileObj.data = event.target.result
          //       fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
          //       fileObj.type = element.type
          //       fileObj.description = ''
          //       this.inputValues[2].value.push(fileObj)
          //       resolve('ok')
          //     }
          //     reader.onerror = error => {
          //       reject(error)
          //     }
          //     reader.readAsDataURL(element)
          //   })
          // })
          // await Promise.all(fileProcessPromises)
          formData.append('inputValues', JSON.stringify(this.inputValues))

          this.$http.post('operation/service-requests/email-task/send', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                // @ts-ignorethis.$emit('update:statusFilter', response.data.data)

                const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
                this.$emit('update:inputValues', slot.inputValues)
                this.$emit('update:fieldValues', slot.fieldValues)
                this.$emit('update:submitDate', slot.submitDate)
                // this.files = []
                this.editMode = false
                this.$swal({
                  title: 'Email Sent',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  cancelButtonText: '',
                  allowOutsideClick: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                // .then(result => {
                //   if (result.value) {
                //     this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
                //   } else {
                //
                //     this.$router.push({ name: 'appointment-bookings-show', params: { id: response.data.data._id } })
                //   }
                // })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .pale-background {
    background: rgba(186, 191, 199, 0.12);
  }
</style>
