<template>
  <div>
    <b-card
      no-body
      class="shadow-body"
    >
      <b-card-body>
        <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
        <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
        <vue-dropzone
          id="event-images"
          ref="myVueDropzone"
          :options="dropzoneOptions"
          :use-custom-slot="true"
          @vdropzone-files-added="processFile"
          @vdropzone-removed-file="fileRemoved"
          @vdropzone-error="handleError"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Drop file here or click to upload
            </h3>
            <div class="subtitle">
              Maximum upload file size : 5MB
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              class="mt-2 no-float"
            >
              Upload file
            </b-button>
          </div>
        </vue-dropzone>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2"
          :disabled="!newFile"
          @click="uploadFile"
        >
          Upload
        </b-button>
      </b-card-body>

      <b-row>
        <b-col
          v-for="(file, key) in inputValues[0].value"
          :key="'tb' + key"
          class="content-header-left mb-2"
          cols="12"
          md="4"
        >
          <div
            v-if="file.data && file.data.startsWith('http')"
          >
            <div
              class="attachment-item"
            >
              <b-img
                v-if="file.type.includes('image')"
                left
                height="40"
                :src="require('@/assets/images/admin/doc_jpg.png')"
                alt="Left image"
              />
              <b-img
                v-if="file.type.includes('application')"
                left
                height="40"
                :src="require('@/assets/images/admin/doc_file.png')"
                alt="Left image"
              />
              <strong>
                <b-link
                  :href="file ? file.data : ''"
                  target="_blank"
                  class="high_opacity"
                >
                  {{ file ? file.name : '' }}
                </b-link>
              </strong>
              <span>{{ file ? `${file.size}mb` : '' }}</span>
              <feather-icon
                icon="XIcon"
                class="remove__doc"
                size="16"
                @click="removeAttachment(file.data)"
              />
            </div>
            <div class="doc__desc">
              <span v-if="file.description">
                {{ file.description }}
              </span>
              <span v-else>
                <button @click="updateDescription(key)">Add a description</button>
              </span>
              <span
                v-if="file.description"
                class="edit__desc"
              >
                <b-button
                  variant="flat-primary"
                  @click="updateDescription(key)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                    size="18"
                  />
                </b-button>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-button
        variant="link"
        class="mr-auto px-0 pt-0"
        @click="$emit('update:tabView', 1)"
      >
        View all uploaded documents
      </b-button>
    </b-card>

    <b-modal
      :id="'modal-attachment-description-'+randID"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col
          md="12"
          class=""
        >
          <div class="demo-inline-spacing ml-auto">
            <b-button
              variant="flat-primary"
              @click="closeDescriptionModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              :disabled="!description || attachmentKey === ''"
              @click="saveDescription()"
            >
              <span class="align-middle">Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row> -->
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BButton, BRow, BCol, BFormTextarea, BImg, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BImg,
    BLink,
    BFormTextarea,

    vueDropzone: vue2Dropzone,
  },
  directives: {
    Ripple,
  },
  props: {
    tabView: {
      type: Number,
      required: false,
      default: 0,
    },
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
  },
  data() {
    const randID = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)
    return {
      randID,
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: this.fieldValues[3] ? this.fieldValues[3].value.toString() : null,
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },
      newFile: false,
      description: '',
      attachmentKey: '',
    }
  },
  async created() {
    if (!this.inputValues.length) {
      const attachmentObj = {}
      attachmentObj.key = 'attachment'
      attachmentObj.value = []
      this.inputValues.push(attachmentObj)
    }

    await this.$nextTick()
    this.$emit('updateCount')
  },

  methods: {
    processFile(file) {
      const acceptedTypes = this.fieldValues[3].value
      file.forEach(element => {
        if (acceptedTypes.includes(element.type)) {
          if (element.size > 5 * 1024 * 1024) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: 'Image is too large',
            //     icon: 'AlertTriangleIcon',
            //     variant: 'danger',
            //   },
            // })
          } else {
            const exist = this.inputValues[0].value.find(o => o.name === element.name && o.size === (element.size / (1024 * 1024)).toFixed(2))
            if (exist) {
              // this.$refs.myVueDropzone.removeFile(element)
              setTimeout(() => {
                if (element.previewElement) {
                  element.previewElement.remove()
                }
              }, 500)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Duplicate File',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                // const found = this.inputValues[0].value.find(image => image === event.target.result)
                // if (found) {
                //   this.$refs.myVueDropzone.removeFile(element)
                //   this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //       title: 'This File Already Exists',
                //       icon: 'AlertTriangleIcon',
                //       variant: 'danger',
                //     },
                //   })
                // } else {
                //   this.newFile = true
                //   const fileObj = {}
                //   fileObj.name = element.name
                //   fileObj.description = ''
                //   fileObj.data = event.target.result
                //   fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                //   fileObj.type = element.type
                //   this.inputValues[0].value.push(fileObj)
                // }
                this.newFile = true
                const fileObj = {}
                fileObj.name = element.name
                fileObj.description = ''
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                fileObj.lastModified = element.lastModified
                this.inputValues[0].value.push(fileObj)
              }
            }
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Unsupported file type',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    fileRemoved(file) {
      // console.log(file)
      if (file.dataURL) {
        this.inputValues[0].value = this.inputValues[0].value.filter(item => item.data !== file.dataURL)
      } else {
        const index = this.inputValues[0].value.findIndex(o => o.name === file.name && o.lastModified === file.lastModified)
        if (index !== -1 && !this.inputValues[0].value[index].data.startsWith('http')) {
          this.inputValues[0].value.splice(index, 1)
        }
        // this.inputValues[0].value = this.inputValues[0].value.filter(item => item.name !== file.name)
      }
      // eslint-disable-next-line prefer-destructuring
      const length = this.$refs.myVueDropzone.dropzone.files.length
      if (length <= 0) {
        this.newFile = false
      }

      const previewLength = document.getElementsByClassName('dz-preview').length
      if (!previewLength) {
        this.$refs.myVueDropzone.removeAllFiles()
      }
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    uploadFile() {
      const formData = new FormData()

      formData.append('serviceRequest', this.serviceRequestId)
      formData.append('block', this.taskBlock)
      formData.append('inputValues', JSON.stringify(this.inputValues))

      this.$http.post('operation/service-requests/file-task/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
            await this.$emit('update:inputValues', slot.inputValues)
            this.$emit('fileChanged')
            this.$refs.myVueDropzone.removeAllFiles()
            this.newFile = false
            this.$swal({
              title: 'File Uploaded',
              html: 'File Uploaded Successfully',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/save.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              cancelButtonText: '',
              allowOutsideClick: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary mr-1',
                cancelButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            // .then(result => {
            //   if (result.value) {
            //     this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
            //   } else {
            //
            //     this.$router.push({ name: 'appointment-bookings-show', params: { id: response.data.data._id } })
            //   }
            // })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'customerName') {
                this.customerNameError = validationError.msg
                this.customerNameValidation = true
              } else if (validationError.param === 'customerContact') {
                this.customerContactError = validationError.msg
                this.customerContactValidation = true
              }
            })

            const items = document.getElementsByClassName('text-danger')
            window.scrollTo({
              top: items[0].offsetTop,
              behavior: 'smooth',
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', `modal-attachment-description-${this.randID}`, '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.inputValues[0].value[key].description
      this.$root.$emit('bv::show::modal', `modal-attachment-description-${this.randID}`, '')
    },
    saveDescription() {
      const url = this.inputValues[0].value[this.attachmentKey].data
      const formData = new FormData()
      formData.append('serviceRequest', this.serviceRequestId)
      formData.append('block', this.taskBlock)
      formData.append('description', this.description)
      formData.append('url', url)
      this.$http.post('operation/service-requests/file-task/save-file-description', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
          await this.$emit('update:inputValues', slot.inputValues)
          this.$emit('fileChanged')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          this.closeDescriptionModal()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    removeAttachment(url) {
      const formData = new FormData()
      formData.append('serviceRequest', this.serviceRequestId)
      formData.append('block', this.taskBlock)
      formData.append('url', url)
      this.$http.post('operation/service-requests/file-task/remove-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          const slot = response.data.data.taskBlocks.find(o => o._id === this.taskBlock)
          await this.$emit('update:inputValues', slot.inputValues)
          this.$emit('fileChanged')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
