<template>

  <div class="service__details task__details">
    <b-row
      class="content-header"
    >
      <b-col
        class="d-block d-md-none"
        cols="12"
      >
        <div class="back_button d-block d-md-none">
          <router-link
            :to="'/operations/tasks'"
          >
            Back to Tasks
          </router-link>
        </div>
      </b-col>

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="9"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <p>Service Request Number</p>
            <h1 class="custom-header-title">
              {{ serviceRequest.stringID }} - {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="3"
        cols="3"
      >
        <div class="">
          <div class="action_button text-right">
            <b-button
              v-b-modal.serviceRequest-detail-modal2
              variant="flat-primary"
            >
              <feather-icon
                icon="LayoutIcon"
                class="mr-50"
                size="20"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-row
      class="content-header v-sticky-sidebar-container service_request_task"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2 top-info-right"
        cols="12"
        md="3"
      >
        <vue-sticky-sidebar
          class="sidebar"
          :top-spacing="topSpacing"
          container-selector=".v-sticky-sidebar-container"
          inner-wrapper-selector=".sidebar__inner"
        >
          <b-card
            no-body
            class="sticky_sidebar mb-50"
          >
            <div class="event-info p-1">
              <div class="sidebar_top">
                <div class="sidebar_left">
                  <div v-if="task.assignees && task.assignees.length">
                    <div
                      v-for="(assignee, index) in task.assignees"
                      :key="index"
                    >
                      <b-avatar
                        v-if="assignee.image"
                        :src="assignee.image"
                      />
                      <b-avatar
                        v-else
                        :text="resolveAcronymName(assignee.name)"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <b-button
                      variant="primary"
                      @click="claimTask(task._id)"
                    >
                      <feather-icon
                        icon="UserPlusIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">Claim Task</span>
                    </b-button>
                  </div>
                </div>
                <div class="sidebar_right">
                  <feather-icon
                    icon="ClockIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span v-if="task.dueDate">{{ dateFormatWithTime(task.dueDate) }}</span>
                  <span v-else>No Due Date</span>
                </div>
              </div>
              <h5>{{ task.taskName }}</h5>
              <p><strong>Applicant:</strong> {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.customerName : '' }} • ({{ serviceRequest.serviceForm ? serviceRequest.serviceForm.customerContact : '' }})</p>
              <p><strong>Deceased:</strong> {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.deceasedName : '' }}</p>
              <div class="action__buttons">
                <b-badge
                  :variant="resolveTaskStatusVariant(task.status)"
                  class="text-capitalize mr-50"
                >
                  {{ task.status }}
                </b-badge>
                <b-badge
                  variant="light-secondary"
                  class="text-capitalize"
                >
                  {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
                </b-badge>
              </div>
            </div>
          </b-card>
        </vue-sticky-sidebar>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right d-md-block mb-1"
        :class="{'no-mouse-event-task': (task.assignees && !task.assignees.length) }"
        md="9"
        cols="12"
      >
        <b-card
          no-body
          class="mb-1"
          :class="{'task_completed': task.completed == true, 'task_skipped': task.skipped == true }"
        >
          <template
            #header
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                :icon="(task.taskBlocks && task.taskBlocks.length > 1) ? 'GridIcon' : ((task.taskBlocks && task.taskBlocks[0].block.icon) ? task.taskBlocks[0].block.icon : '')"
                class=""
                size="20"
              />
            </b-button>
            <h3
              class="align-middle mr-auto"
              :class="{'text-success': task.completed == true }"
            >
              {{ task.taskName }}
            </h3>
            Due on {{ dateFormatWithTime(task.dueDate) }}
            <div
              v-for="(assignee, index) in task.assignees"
              :key="index"
            >
              <b-avatar
                v-if="assignee.image"
                :src="assignee.image"
              />
              <b-avatar
                v-else
                :text="resolveAcronymName(assignee.name)"
              />
            </div>
          </template>
          <div
            :class="{'low-opacity-task': (task.skipped == true || task.completed == true) }"
            class="task__list"
          >
            <b-card
              v-for="(taskBlock, tkey) in task.taskBlocks"
              id=""
              :key="tkey"
              no-body
              class="mb-50"
            >
              <email-template
                v-if="taskBlock.block.name == 'Send Email With Attachment'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <file-upload-template
                v-if="taskBlock.block.name == 'File Upload'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <send-sms-template
                v-if="taskBlock.block.name == 'Send SMS Message'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <send-telegram-message-template
                v-if="taskBlock.block.name == 'Send Telegram Message'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <input-field-template
                v-if="taskBlock.block.name == 'Form' || taskBlock.block.name == 'Input Fields'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <link-template
                v-if="taskBlock.block.name == 'Link'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
              <verification-template
                v-if="taskBlock.block.name == 'Verification'"
                :input-values.sync="taskBlock.inputValues"
                :field-values="taskBlock.fieldValues"
                :custom-fields="taskBlock.customFields"
                :task-block="taskBlock._id"
                :submit-date="taskBlock.submitDate"
                :service-form="serviceRequest.serviceForm"
                :service-request-id="serviceRequest._id"
              />
            </b-card>
          </div>
          <b-card-footer
            :class="{'task_completed': task.completed == true, 'task_skipped': task.skipped == true }"
            class="secondary-card-footer p-1"
          >
            <div v-if="task.completed == false && task.skipped == false">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="button"
                class=""
                @click="markTaskAsComplete(task)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Mark as complete</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                type="button"
                class="ml-50"
                @click="skipTask(task)"
              >
                Skip
              </b-button>
            </div>
            <div v-else-if="task.completed == true">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                type="button"
                class=""
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Marked as completed</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                type="button"
                class="mt-50"
                @click="unmarkTask(task)"
              >
                Undo
              </b-button>
              <span class="float-right mt-75">
                This task was completed on <span class="text-bold-black">{{ dateFormatWithTime(task.completedDate) }}</span> by <span class="text-bold-black">{{ task.completedBy ? task.completedBy.name : '' }}</span>
              </span>
            </div>
            <div v-else-if="task.skipped == true">
              <!-- <b-row class="skip-background">
                <b-col md="11">
                  <div class="p-1">
                    <span class="skip-text">
                      Skipped! This task was skipped on {{ dateFormatWithTime(task.skippedDate) }}
                    </span>
                  </div>
                </b-col>
                <b-col md="1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    type="button"
                    class="mt-50"
                    @click="unskipTask(task)"
                  >
                    Undo
                  </b-button>
                </b-col>
              </b-row> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                type="button"
                class=""
              >
                <feather-icon
                  icon="SkipForwardIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Skipped</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                type="button"
                class=""
                @click="unskipTask(task)"
              >
                Undo
              </b-button>
              <span class="float-right mt-75">
                This task was skipped on <span class="text-bold-black">{{ dateFormatWithTime(task.skippedDate) }}</span> by <span class="text-bold-black">{{ task.skippedBy ? task.skippedBy.name : '' }}</span>
              </span>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="serviceRequest-detail-modal2"
      size="xl"
      title=""
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <div
        class="content-header modal_header_top"
      >

        <!-- Content Left -->
        <div
          class="content-header-left"
        >
          <div class="breadcrumbs-top">
            <div class="request_no">
              <p><strong>Service Request Number</strong></p>
              <h1 class="custom-header-title">
                {{ serviceRequest.stringID }} - {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
              </h1>
              <p class="log_info">
                Created by {{ serviceRequest.createdBy ? serviceRequest.createdBy.name : '' }} on {{ dateFormatWithTime(serviceRequest.createdAt) }}<br>Last updated on {{ dateFormatWithTime(serviceRequest.updatedAt) }}
              </p>
            </div>
          </div>
        </div>
        <!-- Content Right -->
        <div
          class="content-header-right text-md-right d-none d-md-block mb-1"
        >
          <div class="user_block ac_type">
            <div class="user_avatar" />
            <div class="user_info">
              <h6>Status</h6>
              <h5 class="text-capitalize">
                {{ serviceRequest.status ? serviceRequest.status.replace("-", " ") : '' }}
              </h5>
            </div>
          </div>
          <div class="user_block ac_type">
            <div class="user_avatar" />
            <div class="user_info">
              <h6>Service Form</h6>
              <h5 class="text-capitalize">
                {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.stringID : '' }}
              </h5>
            </div>
          </div>
          <div class="user_block">
            <div class="user_avatar">
              <img :src="serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees[0].image ? serviceRequest.assignees[0].image : defaultImage) : defaultImage) : defaultImage">
            </div>
            <div class="user_info">
              <h6>Assignee</h6>
              <h5>{{ serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees.length > 1 ? `${serviceRequest.assignees[0].name} + ${serviceRequest.assignees.length - 1}` : serviceRequest.assignees[0].name) : '') : '' }}</h5>
            </div>
          </div>
        </div>
      </div>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <div class="user_Block d-block d-md-none">
            <horizontal-scroll>
              <div class="outer">
                <div class="user_block">
                  <div class="user_avatar">
                    <img :src="serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees[0].image ? serviceRequest.assignees[0].image : defaultImage) : defaultImage) : defaultImage">
                  </div>
                  <div class="user_info">
                    <h6>Assignee</h6>
                    <h5>{{ serviceRequest.assignees ? (serviceRequest.assignees.length ? (serviceRequest.assignees.length > 1 ? `${serviceRequest.assignees[0].name} + ${serviceRequest.assignees.length - 1}` : serviceRequest.assignees[0].name) : '') : '' }}</h5>
                  </div>
                </div>
                <div class="user_block ac_type">
                  <div class="user_avatar" />
                  <div class="user_info">
                    <h6>Service Form</h6>
                    <h5 class="text-capitalize">
                      {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.stringID : '' }}
                    </h5>
                  </div>
                </div>
                <div class="user_block ac_type">
                  <div class="user_avatar" />
                  <div class="user_info">
                    <h6>Status</h6>
                    <h5 class="text-capitalize">
                      {{ serviceRequest.status ? serviceRequest.status.replace("-", " ") : '' }}
                    </h5>
                  </div>
                </div>
              </div>
            </horizontal-scroll>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left"
          cols="12"
          md="12"
        >
          <h3 class="section__title">
            Deceased Details
          </h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Name of Deceased
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? serviceRequest.serviceForm.deceasedName : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Departed Date
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? (serviceRequest.serviceForm.departedDate ? dateFormat(serviceRequest.serviceForm.departedDate) : '') : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Age & Gender
            </b-th>
            <b-td>
              {{ serviceRequest.serviceForm ? serviceRequest.serviceForm.deceasedAge : '' }},
              <span class="text-capitalize">{{ serviceRequest.serviceForm ? serviceRequest.serviceForm.deceasedGender : '' }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left"
          cols="12"
          md="12"
        >
          <h3 class="section__title">
            Service Request Details
          </h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Type
            </b-th>
            <b-td>
              {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Service Location
            </b-th>
            <b-td>
              {{ serviceRequest.location ? serviceRequest.location : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Remarks
            </b-th>
            <b-td>{{ serviceRequest.remarks ? serviceRequest.remarks : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left"
          cols="12"
          md="12"
        >
          <h3 class="section__title">
            Payment Details
          </h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr class="payment__info">
            <b-th>
              Payment Remarks <br>
              <div class="payment_method">
                {{ serviceRequest.paymentRemarks || '-' }}
              </div>
            </b-th>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <p class="email_reminder">To send reminders and emails, please go to
        <b-link :to="{ name: 'operation-service-forms-show', params: { id: serviceRequest.serviceForm ? serviceRequest.serviceForm._id : '0' } }">
          main service form details.
        </b-link>
      </p>

      <hr class="dividerHR">

      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left"
          cols="12"
          md="12"
        >
          <h3 class="section__title">
            Agent Details
          </h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Agent Name
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? serviceRequest.serviceForm.agentName : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Agent Code
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? serviceRequest.serviceForm.agentCode : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Agent Mobile
            </b-th>
            <b-td>{{ serviceRequest.serviceForm ? serviceRequest.serviceForm.agentContact : '-' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>

<script>
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BRow, BButton, BCol, BBadge, BAvatar, BCardFooter, BLink, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EmailTemplate from '../service-request/EmailTemplate.vue'
import FileUploadTemplate from '../service-request/FileUploadTemplate.vue'
import SendSmsTemplate from '../service-request/SendSmsTemplate.vue'
import SendTelegramMessageTemplate from '../service-request/SendTelegramMessageTemplate.vue'
import InputFieldTemplate from '../service-request/InputFieldTemplate.vue'
import LinkTemplate from '../service-request/LinkTemplate.vue'
import VerificationTemplate from '../service-request/VerificationTemplate.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    BCardFooter,
    BLink,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    HorizontalScroll,

    'vue-sticky-sidebar': vueStickySidebar,

    EmailTemplate,
    FileUploadTemplate,
    SendSmsTemplate,
    SendTelegramMessageTemplate,
    InputFieldTemplate,
    LinkTemplate,
    VerificationTemplate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      serviceRequest: {},
      task: {},
      currentKey: '',
      currentTask: {},

      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo.svg'),
    }
  },
  created() {
    this.$http.get(`operation/service-requests/task/${this.$route.params.id}/show`)
      .then(response => {
        this.task = response.data.data || {}
        this.serviceRequest = response.data.data.serviceRequest || {}
        document.title = `${response.data.data.serviceRequest.stringID} - ${response.data.data.taskName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    markTaskAsComplete(task) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to mark task as complete? You can still edit later on.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Mark as complete',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/complete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.task.completed = response.data.data.completed || ''
                this.task.status = response.data.data.status || ''
                this.task.completedDate = response.data.data.completedDate || []
                this.task.completedBy = response.data.data.completedBy || ''
                this.$swal({
                  title: 'Task has been marked as Complete.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    skipTask(task) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to skip task? You can still undo later on.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Skip Task',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/skip', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.task.skipped = response.data.data.skipped || ''
                this.task.status = response.data.data.status || ''
                this.task.skippedDate = response.data.data.skippedDate || []
                this.task.skippedBy = response.data.data.skippedBy || ''
                this.$swal({
                  title: 'Task has been skipped.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    unskipTask(task) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to unskip the task?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Unskip Task',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/unskip', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.task.skipped = response.data.data.skipped || ''
                this.task.status = response.data.data.status || ''
                this.task.skippedDate = response.data.data.skippedDate || []
                this.task.skippedBy = response.data.data.skippedBy || ''
                this.$swal({
                  title: 'Task has been unskipped.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },

    unmarkTask(task) {
      this.$swal({
        title: 'Are you sure?',
        html: 'Do you want to mark the task as incomplete?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Mark Incomplete',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-success ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', task._id)

            this.$http.patch('operation/service-requests/task/mark/incomplete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.task.completed = response.data.data.completed || ''
                this.task.status = response.data.data.status || ''
                this.task.completedDate = response.data.data.completedDate || []
                this.task.completedBy = response.data.data.completedBy || ''
                this.$swal({
                  title: 'Task has been marked as incomplete.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    resolveTaskStatusVariant(status) {
      if (status === 'completed') return 'light-success'
      if (status === 'skipped') return 'light-danger'
      if (status === 'pending') return 'light-warning'
      return 'light-info'
    },

    claimTask(id) {
      let assignee = ''
      if (this.task.serviceRequest.assignees.length) {
        assignee = this.task.serviceRequest.assignees[0].name
      }
      this.$swal({
        title: 'Are you sure you want to claim this task?',
        html: `System will send a message to ${assignee} (Assignee of the service request) that you will be helping this task.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/claim_icon.svg'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Proceed',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()

            formData.append('serviceRequestTask', id)

            this.$http.patch('operation/service-requests/task/claim', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                this.task.assignees = response.data.data.assignees || []
                this.$swal({
                  title: 'Task has been claimed.',
                  html: '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
  .low-opacity {
    opacity: 0.6;
  }
  .high-opacity div {
    opacity: 1 !important;
  }
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }

  .red-trash-button {
    color: #D91B35 !important;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }

  .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
  }
  .autosuggest__results-container .autosuggest__results {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 40vh;
  }
</style>
