<template>
  <p />
</template>

<script>
export default {
  created() {
    this.$emit('updateCount')
  },
}
</script>
