<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <p>{{ fieldValues[1] ? fieldValues[1].value : '' }}</p>
      <p class="link-tag">
        {{ fieldValues[2] ? fieldValues[2].value : '' }}
      </p>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2"
        :href="fieldValues[2] ? fieldValues[2].value : ''"
        target="_blank"
      >
        <feather-icon
          icon="Link2Icon"
          class="mr-50"
          size="16"
        />
        <span class="align-middle">Visit URL</span>
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
  },
  created() {
    this.$emit('updateCount')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
