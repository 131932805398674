<template>
  <b-card
    no-body
    class="shadow-body"
  >
    <b-card-body>
      <h5>{{ fieldValues[1] ? fieldValues[1].value : '' }}</h5>
      <p>{{ fieldValues[2] ? fieldValues[2].value : '' }}</p>
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="customFieldForm"
          #default="{invalid}"
        >
          <b-row
            v-for="(opt, key) in customFields"
            :key="key"
          >

            <b-col
              md="12"
            >
              <b-form-group
                v-if="opt.type == 'text'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="pre-line-label"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                >
                  <b-form-input
                    :id="'event-custom-field-' + key"
                    v-model="inputValues[key].value"
                    :placeholder="opt.placeholder"
                    :state="(errors.length > 0) ? false : null"
                    :disabled="!canEdit"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'textarea'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="pre-line-label"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                  :vid="'event-custom-field-' + key"
                >
                  <b-form-textarea
                    :id="'event-custom-field-' + key"
                    v-model="inputValues[key].value"
                    trim
                    :placeholder="opt.placeholder"
                    :state="(errors.length > 0) ? false : null"
                    :disabled="!canEdit"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'radio-multiple'"
                label-cols-md="4"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                class="pre-line-label"
                :class="{ 'pointer-none': !canEdit }"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                >

                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="inputValues[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio display-block-checkbox"
                    :state="(errors.length > 0) ? false : null"
                  >
                    <b-form-checkbox
                      v-for="(option, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="option.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.value }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'radio-single'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="mb-margin-0 pre-line-label"
                :class="{ 'pointer-none': !canEdit }"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="opt.label"
                >
                  <b-form-checkbox-group
                    :id="'event-custom-field-' + key"
                    v-model="inputValues[key].value"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio display-block-checkbox"
                    :state="(errors.length > 0) ? false : null"
                    @input="makeCheckboxToRadio(key)"
                  >
                    <b-form-checkbox
                      v-for="(option, kindex) in opt.extraOptions"
                      :key="kindex"
                      :value="option.value"
                      class="mb-50 customCheckbox"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ option.value }}</span>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'block-select'"
                label-cols-md="4"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                class="pre-line-label"
              >
                <validation-provider
                  :name="opt.label"
                >

                  <v-select
                    id="h-event-status"
                    v-model="inputValues[key].value"
                    label="title"
                    :placeholder="opt.placeholder"
                    :options="opt.selectOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    :disabled="!canEdit"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'datasource-select'"
                label-cols-md="4"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                class="pre-line-label"
              >
                <validation-provider
                  :name="opt.label"
                >

                  <v-select
                    id="h-event-status"
                    v-model="inputValues[key].value"
                    :placeholder="opt.placeholder"
                    :options="opt.selectOptions"
                    :clearable="false"
                    :disabled="!canEdit"
                  />
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'time-picker'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="pre-line-label"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="inputValues[key].value"
                    class="form-control"
                    :config="{ wrap: true, noCalendar: true, enableTime: true, dateFormat: 'H:i', disableMobile: true }"
                    value=""
                    :disabled="!canEdit"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="ClockIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                v-if="opt.type == 'date-picker'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="pre-line-label"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="inputValues[key].value"
                    class="form-control"
                    :config="{ wrap: true, dateFormat: 'd/m/Y', disableMobile: true }"
                    value=""
                    :disabled="!canEdit"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                v-if="opt.type == 'file'"
                :label="opt.label"
                :label-for="'event-custom-field-' + key"
                label-cols-md="4"
                class="pre-line-label"
              >
                <b-form-file
                  v-model="inputValues[key].value"
                  :placeholder="opt.placeholder"
                  :disabled="!canEdit"
                  :accept="(Array.isArray(opt.extraOptions[0].value) && opt.extraOptions[0].value.length) ? opt.extraOptions[0].value.toString() : ''"
                  @input="setImage(key)"
                />
                <b-row
                  v-if="!canEdit && inputValues[key].files && inputValues[key].files.length"
                >
                  <!-- Content Left -->
                  <b-col
                    v-for="(file, ind) in inputValues[key].files"
                    :key="ind"
                    class="content-header-left mt-1"
                    cols="12"
                    md="4"
                  >
                    <div
                      class="attachment-item"
                    >
                      <b-img
                        v-if="file.type.includes('image')"
                        left
                        height="40"
                        :src="require('@/assets/images/admin/doc_jpg.png')"
                        alt="Left image"
                      />
                      <b-img
                        v-if="file.type.includes('application')"
                        left
                        height="40"
                        :src="require('@/assets/images/admin/doc_file.png')"
                        alt="Left image"
                      />
                      <strong>
                        <b-link
                          :href="file ? file.data : ''"
                          target="_blank"
                        >
                          {{ file ? file.name : '' }}
                        </b-link>
                      </strong>
                      <span>{{ file ? `${file.size}mb` : '' }}</span>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-if="canEdit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-2"
            :disabled="invalid"
          >
            Save
          </b-button>
          <b-button
            v-if="!canEdit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-success"
            type="submit"
            class="mt-2"
            disabled
          >
            <feather-icon
              icon="CheckSquareIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Saved</span>
          </b-button>
          <b-button
            v-if="!canEdit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            type="submit"
            class="mt-2"
            @click="canEdit = true"
          >
            Edit
          </b-button>
        </validation-observer>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardBody, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckbox,
  BFormCheckboxGroup, BInputGroup, BInputGroupAppend, BFormFile, BForm, BImg, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BForm,
    BImg,
    BLink,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    inputValues: {
      type: Array,
      required: true,
    },
    fieldValues: {
      type: Array,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
    taskBlock: {
      type: String,
      required: true,
    },
    serviceRequestId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      canEdit: false,
    }
  },
  created() {
    if (!this.inputValues.length) {
      this.canEdit = true
      this.customFields.forEach((arrayItem, key) => {
        const bodyObj = {}
        bodyObj.key = arrayItem.label
        if (arrayItem.type === 'radio-multiple' || arrayItem.type === 'radio-single' || arrayItem.type === 'single-select') {
          bodyObj.value = []
        } else if (arrayItem.type === 'file') {
          bodyObj.value = []
          bodyObj.files = []
        } else {
          bodyObj.value = ''
        }
        this.inputValues.push(bodyObj)

        if (arrayItem.type === 'block-select') {
          this.$http.post('operation/work-flows/task/find-block', { block: arrayItem.extraOptions[0].value })
            .then(response => {
              const taskBlock = response.data.data.taskBlocks.find(element => element.fieldValues[0].value === arrayItem.extraOptions[0].value)
              const optionArray = []
              taskBlock.block.fields.forEach(val => {
                if (val.type === 'text') {
                  const newObj = {}
                  newObj.code = val.label
                  newObj.title = val.label
                  optionArray.push(newObj)
                }
              })

              // eslint-disable-next-line no-param-reassign
              arrayItem.selectOptions = optionArray
              this.$set(this.customFields, key, arrayItem)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }

        if (arrayItem.type === 'datasource-select') {
          this.$http.post('operation/work-flows/task/data-source', { source: arrayItem.extraOptions[0].value })
            .then(response => {
              // eslint-disable-next-line no-param-reassign
              arrayItem.selectOptions = response.data.data
              this.$set(this.customFields, key, arrayItem)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    } else {
      this.customFields.forEach((arrayItem, key) => {
        if (arrayItem.type === 'file') {
          this.inputValues[key].value = []
          // eslint-disable-next-line no-prototype-builtins
          if (!this.inputValues[key].hasOwnProperty('files')) {
            this.inputValues[key].files = []
          }
        }
      })
    }

    this.$emit('updateCount')
  },
  methods: {
    makeCheckboxToRadio(key) {
      const lastSelected = this.inputValues[key].value.slice(-1)[0]
      if (lastSelected) {
        this.inputValues[key].value = this.inputValues[key].value.filter(code => code === lastSelected)
      }
    },
    setImage(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.inputValues[index].value)
      reader.onload = () => {
        const fileObj = {}
        fileObj.name = this.inputValues[index].value.name
        fileObj.description = ''
        fileObj.data = reader.result
        fileObj.size = (this.inputValues[index].value.size / (1024 * 1024)).toFixed(2)
        fileObj.type = this.inputValues[index].value.type
        this.inputValues[index].files = []
        this.inputValues[index].files.push(fileObj)
      }
    },

    submitForm() {
      this.$refs.customFieldForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('serviceRequest', this.serviceRequestId)
          formData.append('block', this.taskBlock)
          formData.append('inputValues', JSON.stringify(this.inputValues))

          this.$http.post('operation/service-requests/form-task/save', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                // @ts-ignorethis.$emit('update:statusFilter', response.data.data)
                this.canEdit = false
                // this.$swal({
                //   title: 'Form Value Saved',
                //   html: response.data.message || '',
                //   // eslint-disable-next-line global-require
                //   imageUrl: require('@/assets/images/icons/save.png'),
                //   imageWidth: 80,
                //   imageHeight: 80,
                //   imageAlt: 'Custom Icon',
                //   showCancelButton: false,
                //   cancelButtonText: '',
                //   allowOutsideClick: false,
                //   confirmButtonText: 'Ok',
                //   customClass: {
                //     confirmButton: 'btn btn-primary mr-1',
                //     cancelButton: 'btn btn-primary',
                //   },
                //   buttonsStyling: false,
                // })
                // .then(result => {
                //   if (result.value) {
                //     this.$router.push({ name: 'appointment-bookings-index', params: { type: 'all-booking' } })
                //   } else {
                //
                //     this.$router.push({ name: 'appointment-bookings-show', params: { id: response.data.data._id } })
                //   }
                // })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
</style>
